import React, { useState } from 'react'
import { HyreTheme } from '@hyrestaff/ambry/dist/src/themes/HyreTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { StyledEngineProvider } from '@material-ui/core'
import { CookiesProvider, useCookies } from 'react-cookie'
import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { split, createHttpLink as CreateHttpLink, InMemoryCache, ApolloClient, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { Message } from './MessagePanel/Message'
import { NotifyMessage } from './NotifyMessage'
import { CONSTANTS } from '../lib/constants'

const MessagePanel = ({ groupMessage, showTagManagement }) => {
  const [cookies] = useCookies(['jwt_token', 'current_department_id'])
  const [notifyMsg, setNotifyMsg] = useState({ message: null, type: null })
  const token = cookies.jwt_token
  const webHeader = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }))
  const cable = createConsumer(`${CONSTANTS.SOCKET_HOST}${window.location.host}/cable`)
  const actionCableLink = new ActionCableLink({ cable })
  const httpLink = webHeader.concat(
    new CreateHttpLink({
      uri: '/apisite/v1/graphql',
    }),
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    actionCableLink,
    httpLink,
  )

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={HyreTheme}>
        <ApolloProvider client={client}>
          <CookiesProvider>
            <Message setNotifyMsg={setNotifyMsg} groupMessage={groupMessage} showTagManagement={showTagManagement} />
            <NotifyMessage msg={notifyMsg} />
          </CookiesProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MessagePanel
