import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import _ from 'lodash'
import { CREATE_SHIFT_TYPE, zeroPad } from '../../lib/constants'
import {
  QuantityField,
  SelectAgency,
  SelectAttire,
  SelectBreakHrs,
  SelectBreakQty,
  SelectCertification,
  SelectPositions,
  SelectTime,
  WageField,
} from './ModalItems'
import { ArrowRight } from '../Icons/ArrowRight'
import { toDayAndMonth } from '../helpers/time'
import { TagDropdown } from '../TagDropdown'

import GetDepartment from './queries/getDepartment.gql'
import GetAgencies from './queries/getAgencies.gql'
import GetCertifications from './queries/getCertifications.gql'

const FlexItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const SubText = styled.div`
  color: rgba(0, 0, 0, 0.6);
`

const Wrapper = styled(Grid)`
  margin: 24px 24px 0;
`

const SelectedDate = styled(Grid)`
  display: flex;
  margin: 8px 24px 0;
  padding: 8px 24px;
  align-items: center;
  gap: 4px;
  padding-top: 8px;
  background-color: #f4f6f8;
  justify-content: center;
  font-size: 11px;
`

const Date = styled.div`
  border-radius: 1em;
  padding: 2px 6px;
  color: #f9fafb;
  font-size: 9px;
  &.${CREATE_SHIFT_TYPE.INHOUSE_SHIFT} {
    background-color: #868994;
  }
  &.${CREATE_SHIFT_TYPE.HYRE_SHIFT} {
    background-color: #47c1bf;
  }
  &.${CREATE_SHIFT_TYPE.AGENCY_SHIFT} {
    background-color: #97746a;
  }
`

const LabelField = styled(FormLabel)`
  color: #000;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
  text-transform: none;
  span {
    font-weight: normal;
    font-style: italic;
  }
`

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 12px;
    font-size: 14px;
    height: auto;
  }
  .MuiOutlinedInput-notchedOutline {
    color: #c4cdd5;
  }
  .Mui-focused {
    border: none;
    :not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #00848e !important;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
          0 0 8px rgb(102 175 233 / 60%);
      }
    }
  }
`

const ShiftTime = styled(Grid)`
  border-top: 0.5px solid #919eab;
  border-bottom: 0.5px solid #919eab;
  border-right: 1px dashed #919eab;
  background-color: #f4f6f8;
  padding: 11px 20px;
`

const BreakField = styled(Grid)`
  border-top: 0.5px solid #919eab;
  border-bottom: 0.5px solid #919eab;
  background-color: #f4f6f8;
  padding: 11px 20px;
`

const BreakWrap = styled(FlexItem)`
  align-items: start;
`

const TotalWrap = styled(FlexItem)`
  justify-content: center;
  height: 100%;
`

const LabelFormController = styled(FormControlLabel)`
  margin: 9px 0 0;
  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiFormControlLabel-label {
    margin-left: 12px;
    font-size: 15px;
    font-weight: bold;
  }
`

const RatingLabel = styled(LabelField)`
  justify-content: center;
`

const SimpleRating = styled(Rating)`
  .MuiRating-iconFilled {
    color: #1aa8a4;
  }
  .MuiRating-iconHover {
    color: #05a7a4;
  }
  .MuiRating-iconEmpty {
    color: #87c9c7;
  }
`

const RatingStar = styled(SimpleRating)`
  .MuiRating-icon {
    width: 40px;
    justify-content: center;
  }
`

const StyledRadioGroup = styled(RadioGroup)`
  span {
    font-size: 14px;
  }
`

const ErrorText = styled(FormHelperText)`
  margin: 3px 14px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
`

const MultipleError = styled(ErrorText)`
  font-size: 14px;
`

const StyledTextarea = styled(TextareaAutosize)(
  ({ error }) => `
  width: 100%;
  padding: 7px 12px;
  font-size: 14px;
  border-color: ${error === 'error' ? '#DE3618' : 'rgba(0, 0, 0, 0.23)'};
  border-radius: 4px;
  &:hover {
    border-color: rgba(0, 0, 0, 0.23);
  }
  &:focus {
    outline: none;
    border: 1px solid #00a7a4 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(0 167 164 / 60%);
  }
`,
)

export const PositionInfo = ({
  open,
  type,
  dates,
  isEdit,
  defaultValues,
  eventId,
  positionId,
  tags,
  setTags,
  showTagManagement,
}) => {
  const [editShiftData, setEditShiftData] = useState(null)
  const [positionsSelection, setPositionsSelection] = useState([])
  const [agencySelection, setAgencySelection] = useState([])
  const [attires, setAttires] = useState([])
  const [attireOrder, setAttireOrder] = useState([])
  const [minQty, setMinQty] = useState(1)
  const [lowestWage, setLowestWage] = useState(0)
  const [certifications, setCertifications] = useState([])
  const [certificate, setCertificate] = useState('')
  const [errorTime, setErrorTime] = useState('')
  const [errorWage, setErrorWage] = useState('')
  const [minBilledRate, setMinBilledRate] = useState(0)
  const [precision, setPrecision] = useState(1)
  const [hasPaid, setHasPaid] = useState(false)
  const [options, setOptions] = useState(null)

  const { control, watch, getValues, setValue, reset } = useFormContext()

  const watchPositionId = watch('shiftDetails.adminPositionListId')
  const watchAttire = watch('attireDetails.attireIds')
  const watchStartTime = watch([
    'shiftDetails.startHrs',
    'shiftDetails.startMins',
  ])
  const watchEndTime = watch(['shiftDetails.endHrs', 'shiftDetails.endMins'])

  useQuery(GetAgencies, {
    variables: {
      eventId,
      positionListId: watchPositionId,
    },
    skip: type !== CREATE_SHIFT_TYPE.AGENCY_SHIFT || !watchPositionId,
    onCompleted: ({ currentUser }) => {
      const { department } = currentUser.organizer.event
      const { agencyPositionLists } = department
      const selectedAgency = getValues('shiftDetails.partnershipId')
      const agency = agencyPositionLists.find(
        (agc) => agc.department.id === selectedAgency,
      )
      setAgencySelection(agencyPositionLists)
      setMinBilledRate((agency || agencyPositionLists[0]).wage)
      if (
        isEdit
        && watchPositionId
        && watchPositionId === editShiftData.positionList.id
      ) {
        setValue('shiftDetails.partnershipId', editShiftData.department.id)
        setValue('shiftDetails.wage', editShiftData.wage)
        setMinBilledRate(editShiftData.wage)
      } else if (agencyPositionLists.length > 0) {
        setValue(
          'shiftDetails.partnershipId',
          (agency || agencyPositionLists[0]).department.id,
        )
        setValue('shiftDetails.wage', (agency || agencyPositionLists[0]).wage)
      }
    },
  })

  useQuery(GetCertifications, {
    variables: {
      eventId,
      positionListId: watchPositionId,
    },
    skip: type !== CREATE_SHIFT_TYPE.HYRE_SHIFT || !watchPositionId,
    onCompleted: ({ currentUser }) => {
      const { defaultCertifications } = currentUser.organizer.event
      if (
        isEdit
        && watchPositionId
        && watchPositionId === editShiftData.positionList.id
      ) {
        setValue(
          'shiftRequirements.certificationIds',
          editShiftData.certifications.map((cert) => cert.id),
        )
      } else {
        setValue(
          'shiftRequirements.certificationIds',
          defaultCertifications.map((cert) => cert.id),
        )
      }
    },
  })

  const checkTime = () => {
    let hr
    if (type !== CREATE_SHIFT_TYPE.HYRE_SHIFT) {
      return
    }
    const startHrs = getValues('shiftDetails.startHrs')
    const startMins = getValues('shiftDetails.startMins')
    const endHrs = getValues('shiftDetails.endHrs')
    const endMins = getValues('shiftDetails.endMins')
    const totalHours = (Number(endHrs) - Number(startHrs)) * 60
    const totalMinutes = Number(endMins) - Number(startMins)
    const countMins = totalHours + totalMinutes

    if ((countMins < 240 && countMins > 0) || countMins < -1200) {
      hr = String((Number(startHrs) + 4) % 24)
      if (hr < 12) hr = zeroPad(hr, 2)
      setValue('shiftDetails.endHrs', hr)
      setValue('shiftDetails.endMins', startMins)
      setErrorTime('Shift was too short. End Time Adjusted.')
    } else if (countMins > 720 || (countMins < 0 && countMins > -720)) {
      hr = String((Number(startHrs) + 12) % 24)
      if (hr < 12) hr = zeroPad(hr, 2)
      setValue('shiftDetails.endHrs', hr)
      setValue('shiftDetails.endMins', startMins)
      setErrorTime('Shift was too long. End Time Adjusted.')
    } else {
      setErrorTime('')
    }
  }

  useQuery(GetDepartment, {
    variables: {
      eventId,
      type,
      positionId,
    },
    onCompleted: ({
      currentUser,
      positionAttires,
      attiresCategoryOrder,
      certificationLists,
      hyreAdminPositionLists,
    }) => {
      const {
        date,
        fullTimezoneName,
        department,
        positions,
      } = currentUser.organizer.event
      const { defaultPosition, adminPositionLists } = department
      const {
        attireTypes,
        qty,
        assignOnly,
        department: agency,
        positionList,
        ratingRequired,
        startTimeIso8601: startTime,
        endTimeIso8601: endTime,
        breakHrs,
        breakQty,
        wage,
        orderDetail,
        details,
        attireDescription,
        minWageAvailable,
        unpaid,
        inhouseOptions,
        tags: tgs,
        certifications: listCert,
        filledShifts,
      } = positionId ? positions[0] : defaultPosition
      setAttires(_.groupBy(positionAttires, 'listingCategory'))
      setAttireOrder(attiresCategoryOrder)
      setCertifications(certificationLists)
      setEditShiftData(isEdit && positions[0])
      setTags(tgs)
      setOptions(positionId ? inhouseOptions : department.inhouseOptions)
      setHasPaid(
        filledShifts.items.length > 0
          && filledShifts.items.some((sh) => sh.paid),
      )
      if (type === CREATE_SHIFT_TYPE.HYRE_SHIFT) {
        setPositionsSelection(hyreAdminPositionLists)
      } else {
        setPositionsSelection(adminPositionLists)
      }
      if (!getValues('shiftDetails.adminPositionListId')) {
        setValue('shiftDetails.adminPositionListId', positionList.id)
      }
      setValue('shiftDetails.date', date)
      setValue('shiftDetails.fullTimezoneName', fullTimezoneName)
      setValue('shiftDetails.qty', qty)
      setMinQty(_.get(positions[0], 'filledShifts.totalCount', 1) || 1)
      setValue('shiftDetails.breakQty', breakQty)
      setValue('shiftDetails.breakDuration', breakHrs)
      setValue('shiftDetails.unpaid', unpaid)
      setValue('shiftDetails.startHrs', zeroPad(dayjs(startTime).hour(), 2))
      setValue('shiftDetails.startMins', zeroPad(dayjs(startTime).minute(), 2))
      setValue('shiftDetails.endHrs', zeroPad(dayjs(endTime).hour(), 2))
      setValue('shiftDetails.endMins', zeroPad(dayjs(endTime).minute(), 2))
      setValue('shiftDetails.details', details)
      setValue('shiftDetails.wage', wage)
      setValue(
        'attireDetails.attireIds',
        attireTypes.map((attire) => attire.id),
      )
      setValue('attireDetails.attireDescription', attireDescription)
      setValue('shiftRequirements.assignOnly', assignOnly)
      if (type === CREATE_SHIFT_TYPE.HYRE_SHIFT) {
        setLowestWage(minWageAvailable)
        setValue(
          'shiftRequirements.certificationIds',
          listCert.map((cert) => cert.id),
        )
        setValue('shiftRequirements.ratingRequired', ratingRequired)
      }
      if (type === CREATE_SHIFT_TYPE.AGENCY_SHIFT) {
        if (agency) {
          setValue('shiftDetails.partnershipId', agency.id)
        }
        setValue('orderDetails', _.omit(orderDetail, '__typename'))
      }
      checkTime()
    },
  })

  useEffect(() => {
    if (!open) {
      reset(defaultValues)
      setErrorTime('')
      setErrorWage('')
    }
  }, [defaultValues, open, reset])

  const inputStaffWage = useMemo(
    () => type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT
      && _.get(options, 'dynamicStaffWage', false),
    [options, type],
  )

  const inputAttire = useMemo(
    () => (type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT
        && !_.get(options, 'inHouseUniform', false))
      || (type === CREATE_SHIFT_TYPE.AGENCY_SHIFT
        && !_.get(options, 'agencyUniform', false)),
    [options, type],
  )

  const handleAttireSelected = (value) => {
    const listAttire = getValues('attireDetails.attireIds')
    if (listAttire.includes(value)) {
      const index = listAttire.indexOf(value)
      listAttire.splice(index, 1)
    } else {
      listAttire.push(value)
    }
    setValue('attireDetails.attireIds', listAttire)
  }

  const totalHours = useMemo(() => {
    const startAt = dayjs()
      .set('h', Number(watchStartTime[0]))
      .set('m', Number(watchStartTime[1]))
      .set('ms', 0)
    let endAt = dayjs()
      .set('h', Number(watchEndTime[0]))
      .set('m', Number(watchEndTime[1]))
      .set('ms', 0)
    if (startAt.diff(endAt) > 0) {
      endAt = endAt.add(1, 'd')
    }
    return endAt.diff(startAt, 'h', true)
  }, [watchStartTime, watchEndTime])

  return (
    <Grid container>
      <SelectedDate item xs={12}>
        <div>Selected Date(s)</div>
        {dates.map((date) => (
          <Date key={date} className={type}>{`${toDayAndMonth(date)}`}</Date>
        ))}
      </SelectedDate>
      <Wrapper
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Grid item xs={5}>
          <LabelField htmlFor="adminPositionListId">Position</LabelField>
          <Controller
            name="shiftDetails.adminPositionListId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectPositions
                value={value}
                onChange={onChange}
                disabled={hasPaid}
                positions={positionsSelection}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <LabelField htmlFor="qty">Qty</LabelField>
          <Controller
            name="shiftDetails.qty"
            control={control}
            render={({ field: { value, onChange } }) => (
              <QuantityField
                hasPaid={hasPaid}
                value={value}
                min={minQty}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Wrapper>
      <Grid item xs={12} container marginTop={3}>
        <ShiftTime item xs={7}>
          <LabelField>Shift Time</LabelField>
          <FlexItem>
            <Controller
              name="shiftDetails.startHrs"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  id="startHrs"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                    checkTime()
                  }}
                  listTime={[...Array(24).keys()]}
                />
              )}
            />
            :
            <Controller
              name="shiftDetails.startMins"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  id="startMins"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                    checkTime()
                  }}
                  listTime={[0, 15, 30, 45]}
                />
              )}
            />
            <Controller
              name="shiftDetails.endHrs"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  id="endHrs"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                    checkTime()
                  }}
                  listTime={[...Array(24).keys()]}
                />
              )}
            />
            :
            <Controller
              name="shiftDetails.endMins"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  id="endMins"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                    checkTime()
                  }}
                  listTime={[0, 15, 30, 45]}
                />
              )}
            />
          </FlexItem>
        </ShiftTime>
        <BreakField item xs={5}>
          {type !== CREATE_SHIFT_TYPE.HYRE_SHIFT ? (
            <BreakWrap>
              <div>
                <LabelField htmlFor="breakDuration">Break(s)</LabelField>
                <Controller
                  name="shiftDetails.breakDuration"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectBreakHrs
                      value={value}
                      disabled={hasPaid}
                      onChange={onChange}
                    />
                  )}
                />
                <LabelFormController
                  control={(
                    <Controller
                      name="shiftDetails.unpaid"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          id="unpaid"
                          color="primary"
                          checked={value}
                          onChange={onChange}
                          disabled={hasPaid}
                        />
                      )}
                    />
                  )}
                  label="Unpaid"
                />
              </div>
              <div>
                <LabelField htmlFor="breakQty">Qty</LabelField>
                <Controller
                  name="shiftDetails.breakQty"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectBreakQty
                      value={value}
                      disabled={hasPaid}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </BreakWrap>
          ) : (
            <TotalWrap>{`${totalHours} Hrs Shift`}</TotalWrap>
          )}
        </BreakField>
      </Grid>
      {errorTime && (
        <Wrapper item xs={12} textAlign="center">
          <ErrorText error>{errorTime}</ErrorText>
        </Wrapper>
      )}
      {inputStaffWage && (
        <Wrapper item xs={12} container>
          <Grid item xs={8}>
            <Tooltip
              title={`By entering a new rate, you will overwrite the current staff pay rate. 
              Ensure this change is intended, as it will impact payroll calculations.`}
            >
              <LabelField>Dynamic staff hourly rate (optional) ...</LabelField>
            </Tooltip>
            <Controller
              name="shiftDetails.wage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <WageField
                  value={value}
                  onChange={onChange}
                  disabled={hasPaid}
                  min={0}
                  nullable
                />
              )}
            />
          </Grid>
        </Wrapper>
      )}
      {type === CREATE_SHIFT_TYPE.AGENCY_SHIFT && (
        <Wrapper item xs={12} container>
          <Grid item xs={5}>
            <LabelField htmlFor="partnershipId">Agency Selected</LabelField>
            <Controller
              name="shiftDetails.partnershipId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectAgency
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value)
                    const { wage } = agencySelection.find(
                      (agency) => agency.department.id === target.value,
                    )
                    setValue('shiftDetails.wage', wage)
                    setMinBilledRate(wage)
                  }}
                  agencies={agencySelection}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <LabelField>Billed Rate/hr</LabelField>
            <Controller
              name="shiftDetails.wage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <WageField
                  value={value}
                  onChange={onChange}
                  min={minBilledRate}
                  hasError
                  errorWage={errorWage}
                  setErrorWage={setErrorWage}
                />
              )}
            />
          </Grid>
        </Wrapper>
      )}
      {type === CREATE_SHIFT_TYPE.HYRE_SHIFT && (
        <Wrapper item xs={12} container>
          <Grid item xs={5}>
            <LabelField>Billed Rate/hr</LabelField>
            <Controller
              name="shiftDetails.wage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <WageField
                  value={value}
                  onChange={onChange}
                  min={lowestWage}
                  hasError
                  errorWage={errorWage}
                  setErrorWage={setErrorWage}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={6} textAlign="center">
            <RatingLabel>Minimum Rating</RatingLabel>
            <Controller
              control={control}
              name="shiftRequirements.ratingRequired"
              render={({ field: { value, onChange } }) => (
                <RatingStar
                  value={value}
                  precision={precision}
                  onChangeActive={(event) => {
                    if (event.type === 'mousemove') {
                      setPrecision(1)
                    } else if (event.type === 'mouseleave') {
                      setPrecision(0.5)
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue === 5) {
                      onChange(4.5)
                    } else {
                      onChange(newValue)
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Wrapper>
      )}
      {errorWage && (
        <Wrapper item xs={12} textAlign="center">
          <ErrorText error>{errorWage}</ErrorText>
        </Wrapper>
      )}
      <Wrapper item xs={12} container>
        <LabelField>{`Attire ${inputAttire ? 'Description' : ''}`}</LabelField>
        {!inputAttire
          && _.intersection(['1', '2', '3', '4'], watchAttire).length >= 2 && (
          <MultipleError error>
            If multiple articles of the same type are selected, staff will
            bring all of them and need a place to change.
          </MultipleError>
        )}
        {inputAttire ? (
          <Controller
            name="attireDetails.attireDescription"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledTextField
                id="attireDescription"
                variant="outlined"
                value={value}
                onChange={onChange}
                disabled={hasPaid}
                fullWidth
                placeholder="Attire Description"
              />
            )}
          />
        ) : (
          <SelectAttire
            attires={attires}
            watchAttire={watchAttire}
            attireOrder={attireOrder}
            handleAttireSelected={handleAttireSelected}
            disabled={hasPaid}
          />
        )}
      </Wrapper>
      {showTagManagement && type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT && (
        <Wrapper item xs={12}>
          <LabelField>Add tags (optional)</LabelField>
          <TagDropdown tags={tags} setTags={setTags} type="position" />
        </Wrapper>
      )}
      {type === CREATE_SHIFT_TYPE.HYRE_SHIFT && (
        <SelectCertification
          certificate={certificate}
          certifications={certifications}
          setCertificate={setCertificate}
        />
      )}
      {type === CREATE_SHIFT_TYPE.HYRE_SHIFT && (
        <Wrapper xs={12} item>
          <LabelFormController
            control={(
              <Controller
                control={control}
                name="shiftRequirements.heavyLifting"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    color="primary"
                    id="isLifting"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
            label="Heavy Lifting Required?"
          />
        </Wrapper>
      )}
      {type === CREATE_SHIFT_TYPE.AGENCY_SHIFT && (
        <Wrapper item xs={12} container>
          <Grid item xs={12}>
            <LabelField>Order Details to Agency</LabelField>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={6}>
              <Controller
                name="orderDetails.signUpLocation"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTextField
                    id="signUpLocation"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder="Add meeting spot"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="orderDetails.poNumber"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTextField
                    id="poNumber"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder="Po Number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="orderDetails.contactName"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTextField
                    id="contactName"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder="Name"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="orderDetails.contactNumber"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTextField
                    id="contactNumber"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder="Phone No"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="orderDetails.orderNotes"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledTextField
                    id="notes"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder="Order Notes"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Wrapper>
      )}
      <Wrapper xs={12} item>
        <LabelField htmlFor="details">
          {type === CREATE_SHIFT_TYPE.HYRE_SHIFT
            && 'Hyre Shift Information (optional)'}
          {type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT
            && 'In-House Shift Information (optional)'}
          {type === CREATE_SHIFT_TYPE.AGENCY_SHIFT
            && 'Agency Shift Information (optional)'}
        </LabelField>
        <Controller
          name="shiftDetails.details"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledTextarea
              id="details"
              minRows={3}
              disabled={type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT && hasPaid}
              placeholder="Please include any information specific to this shift that staff should know"
              value={value || ''}
              onChange={onChange}
            />
          )}
        />
      </Wrapper>
      {type === CREATE_SHIFT_TYPE.INHOUSE_SHIFT && (
        <Wrapper xs={12} item>
          <LabelField>
            Do you want your
            <b> eligible </b>
            staff to self-schedule?
          </LabelField>
          <Controller
            name="shiftRequirements.assignOnly"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledRadioGroup
                id="assignOnly"
                name="assignOnly"
                value={
                  value === true
                    ? 'Assign Only'
                    : 'Eligible In House Staff Can Pick Up Shifts'
                }
                onChange={({ target }) => {
                  if (target.value === 'Assign Only') {
                    onChange(true)
                  } else {
                    onChange(false)
                  }
                }}
              >
                <LabelFormController
                  value="Assign Only"
                  disabled={hasPaid}
                  control={<Radio size="small" color="primary" />}
                  label={(
                    <FlexItem>
                      <b>No</b>
                      <ArrowRight />
                      <SubText>Assign-only shifts</SubText>
                    </FlexItem>
                  )}
                />
                <LabelFormController
                  value="Eligible In House Staff Can Pick Up Shifts"
                  disabled={hasPaid}
                  control={<Radio color="primary" size="small" />}
                  label={(
                    <FlexItem>
                      <b>Yes</b>
                      <ArrowRight />
                      <SubText>Open shifts</SubText>
                    </FlexItem>
                  )}
                />
              </StyledRadioGroup>
            )}
          />
        </Wrapper>
      )}
    </Grid>
  )
}
