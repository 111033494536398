import React from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@material-ui/core'

const LoadingIcon = styled.div`
  margin: 1em;
  text-align: center;
`

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
`

export const Loading = ({ className }) => (
  <LoadingIcon className={className}>
    <CircularProgress />
  </LoadingIcon>
)

export const AbsoluteLoading = ({ className, size }) => (
  <LoadingWrapper>
    <LoadingIcon className={className}>
      <CircularProgress size={size} />
    </LoadingIcon>
  </LoadingWrapper>
)
