export const sampleScheduleData = [
  {
    'Event Name': '',
    'Venue Name': '',
    Address: '',
    'Meeting Spot': '',
    'Contact Name & Phone No': '',
    'Event Details': '',
    'Free Staff Parking Available?': '',
    'PO# (Optional)': '',
    'Catering Company(Optional)': '',
    Date: '',
    'Position Name': '',
    Qty: '',
    'Start Time': '',
    'End Time': '',
    'Break(s)': '',
    'Break Qty': '',
    'Staff Pay Rate/hr': '',
    'Uniform Requirements (Optional)': '',
    'In-House Shift Information (Optional)': '',
    'Shift Type': '',
  },
]
