import React, { useRef, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { HyreTheme as theme } from '@hyrestaff/ambry/dist/src/themes/HyreTheme'
import {
  Box,
  CircularProgress,
  Divider,
  StyledEngineProvider,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Axios from 'axios'
import styled from '@emotion/styled'
import { saveAs } from 'file-saver'
import { Parser } from '@json2csv/plainjs'
import _ from 'lodash'
import { NotifyMessage } from './NotifyMessage'
import { sampleScheduleData } from './helpers/sampleScheduleData'

const GreenButton = styled(Button)`
  background-color: #00a7a4;
  text-transform: capitalize;
  padding-right: 56px;
  width: 160px;
`

const ArrowButton = styled(Button)`
  padding: 6px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
`

const CustomMenu = styled(MenuItem)`
  cursor: default;
  padding: 0;
  &:hover {
    background-color: unset;
  }
`

const ExportBtn = styled(Box)`
  cursor: pointer;
  height: 36px;
  padding: 9px 10px;
  transition: 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const CustomLabel = styled.label`
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  padding: 6px 16px 6px 16px;
  transition: 0.3s;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.importing {
    cursor: default;
    .name {
      opacity: 0.4;
    }
  }
`

const ImportingWrapper = styled(Box)`
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LinearProgressWithLabel = ({ value }) => (
  <Box position="relative" display="inline-flex">
    {value ? (
      <>
        <CircularProgress variant="determinate" value={value} size={25} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            fontSize={10}
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </>
    ) : (
      <CircularProgress size={25} />
    )}
  </Box>
)

const ExportCSV = ({ data, filename, importing }) => {
  const exportCSV = () => {
    const parser = new Parser()
    const csv = parser.parse(data)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, filename)
  }

  return (
    <Tooltip title="Download template">
      <ExportBtn
        className={`${importing ? 'importing' : ''}`}
        component="div"
        onClick={exportCSV}
      >
        <GetAppIcon fontSize="small" />
      </ExportBtn>
    </Tooltip>
  )
}

const CreateEvent = ({ path, isAgencyShiftEnabled, inHouseStaffEnabled }) => {
  const anchorRef = useRef(null)
  const [importing, setImporting] = useState(false)
  const [notifyMsg, setNotifyMsg] = useState({ message: null, type: null })
  const [toggle, setToggle] = useState(false)

  const handleEvent = () => {
    setToggle(false)
    window.open(path, '_self')
  }

  const handleAgency = () => {
    setToggle(false)
    window.dispatchEvent(new Event('openShiftCreation'))
  }

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const hasVMS = inHouseStaffEnabled || isAgencyShiftEnabled

  const title = () => {
    if (inHouseStaffEnabled && isAgencyShiftEnabled) {
      return 'Agency/In-House Shift'
    }
    if (inHouseStaffEnabled) {
      return 'In-House Shift'
    }
    if (isAgencyShiftEnabled) {
      return 'Agency Shift'
    }
    return null
  }

  const onSelectFile = (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('csv_file', file)
    setImporting(true)
    Axios.post('/apisite/v1/imports/events', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        if (_.get(data, 'data.message', false)) {
          setNotifyMsg({
            message: 'Data imported successfully!',
            type: 'success',
          })
        } else {
          setNotifyMsg({
            message: `Your CSV file has been processed. 
              All valid records have been successfully imported. 
              Records with errors were not imported. 
              Please check the download file to re-import.`,
            type: 'success',
          })
          const blob = new Blob([data], { type: 'text/csv' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'ImportScheduleFailedRecords.csv'
          a.click()
          window.URL.revokeObjectURL(url)
        }
        window.dispatchEvent(new Event('onAgencyShiftCreate'))
        window.dispatchEvent(new CustomEvent('refetch-shifts'))
      })
      .catch((error) => {
        setNotifyMsg({
          message: error.response.data.data.message,
          type: 'error',
        })
      })
      .finally(() => setImporting(false))
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="Create Event"
          >
            <GreenButton onClick={handleToggle}>Create new</GreenButton>
            <ArrowButton
              color="primary"
              disableRipple
              disableFocusRipple
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </ArrowButton>
          </ButtonGroup>
          <Popper
            open={toggle}
            anchorEl={anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              /* eslint-disable react/jsx-props-no-spreading */
              <Grow {...TransitionProps} style={{ transformOrigin: 'bottom' }}>
                <Paper style={{ width: '190px' }}>
                  <MenuList>
                    <MenuItem onClick={handleEvent}>Event</MenuItem>
                    {hasVMS && (
                      <MenuItem onClick={handleAgency}>{title()}</MenuItem>
                    )}
                    <CustomMenu disableRipple>
                      <Wrapper>
                        <CustomLabel
                          className={`${importing ? 'importing' : ''}`}
                          htmlFor="createUploadCSV"
                        >
                          <input
                            accept=".csv"
                            style={{ display: 'none' }}
                            id="createUploadCSV"
                            type="file"
                            disabled={importing}
                            onChange={onSelectFile}
                            onClick={(e) => {
                              e.target.value = null
                            }}
                          />
                          <div className="name">Import Schedule</div>
                          {importing && (
                            <ImportingWrapper>
                              <LinearProgressWithLabel />
                            </ImportingWrapper>
                          )}
                        </CustomLabel>
                        <Divider orientation="vertical" flexItem />
                        <ExportCSV
                          importing={importing}
                          data={sampleScheduleData}
                          filename="Template.csv"
                        />
                      </Wrapper>
                    </CustomMenu>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
        <NotifyMessage msg={notifyMsg} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CreateEvent
