/* eslint-disable no-alert */
import React, { useMemo, useState } from 'react'
import { ApolloClient, InMemoryCache, createHttpLink as CreateHttpLink, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useCookies } from 'react-cookie'
import { HyreTheme } from '@hyrestaff/ambry/dist/src/themes/HyreTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Button, IconButton, StyledEngineProvider } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import styled from '@emotion/styled'
import { PositionModal } from './CreatePosition/PositionModal'
import { SHIFT_TYPE_ENUM } from '../lib/constants'

const StyledButton = styled(Button)`
  color: #ffffff;
  background-color: #00a7a4;
  border: 1px solid #007f79;
  font-weight: 600;
  text-transform: capitalize;
  :hover {
    background-color: #00bfb1;
    border: 1px solid #00bfb1;
  }
  &.Mui-disabled {
    border: none;
  }
`

const EditButton = styled(IconButton)`
  color: #dfa01e;
`

const CreatePosition = ({
  type,
  isEdit,
  dates,
  disabled,
  eventId,
  positionId,
  disableEdit,
  disableAgency,
  showTagManagement,
}) => {
  const [cookies] = useCookies(['jwt_token'])
  const [open, setOpen] = useState(false)
  const typeText = useMemo(() => {
    if (type === SHIFT_TYPE_ENUM.AGENCY) {
      return 'Agency'
    }
    if (type === SHIFT_TYPE_ENUM.HYRE) {
      return 'Hyre'
    }
    return 'In-House'
  }, [type])
  const title = isEdit ? `Edit ${typeText} Position` : `Add ${typeText} Position`
  const headerTitle = isEdit ? `Edit ${typeText} Shift(s)` : `Add ${typeText} Shift(s)`
  const webHeader = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: cookies.jwt_token ? `Bearer ${cookies.jwt_token}` : '',
    },
  }))

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: webHeader.concat(
      new CreateHttpLink({
        uri: '/apisite/v1/graphql',
      }),
    ),
  })

  const handleOpenEdit = () => {
    if (disableEdit) {
      window.alert('Published events that start within 2 days or contain filled shifts cannot be edited')
    } else {
      setOpen(true)
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={HyreTheme}>
        <ApolloProvider client={apolloClient}>
          {isEdit ? (
            <EditButton disabled={disableAgency} onClick={handleOpenEdit}>
              <EditIcon style={{ color: disableAgency ? 'rgba(0, 0, 0, 0.26)' : '#F49342' }} />
            </EditButton>
          ) : (
            <StyledButton disabled={disabled} variant="contained" onClick={() => setOpen(true)}>
              {title}
            </StyledButton>
          )}
          {open && (
            <PositionModal
              open={open}
              onClose={() => setOpen(false)}
              type={type}
              isEdit={isEdit}
              title={headerTitle}
              eventId={eventId}
              positionId={positionId}
              dates={dates}
              showTagManagement={showTagManagement}
            />
          )}
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CreatePosition
