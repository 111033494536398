import React, { useState } from 'react'
import { Button, StyledEngineProvider } from '@material-ui/core'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { useCookies } from 'react-cookie'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { HyreTheme } from '@hyrestaff/ambry/dist/src'
import { PositionInviteModal } from './PositionInviteModal/PositionInviteModal'

const useStyles = makeStyles({
  inviteButton: {
    borderColor: 'rgba(113,114,116,0.25) !important',
    color: '#000 !important',
    padding: '8px 10px !important',
    textTransform: 'none !important',
    width: 115,
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  inviteMoreButton: {
    float: 'right !important',
    textTransform: 'none !important',
    width: 130,
    fontWeight: '600 !important',
  },
})

const InviteStaff = ({ positionId, inviteMore }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [cookies] = useCookies(['jwt_token'])

  const token = cookies.jwt_token
  const client = new ApolloClient({
    uri: '/apisite/v1/graphql',
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={HyreTheme}>
          <Button
            className={`${inviteMore === true ? `${classes.inviteMoreButton} btn btn-primary` : classes.inviteButton}`}
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            {inviteMore === true ? 'Invite More Staff' : 'Private Shift'}
          </Button>
          {open && <PositionInviteModal open={open} setOpen={setOpen} positionId={positionId} />}
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  )
}

export default InviteStaff
