import React, { useState, useCallback, useMemo, useEffect } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import { ThemeProvider } from '@material-ui/core/styles'
import { HyreTheme } from '@hyrestaff/ambry/dist/src'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Grid } from '@material-ui/core'
import { Title } from './Title'
import { PositionDialog } from './PositionDialog'
import { TimeDialog } from './TimeDialog'
import { DateDialog } from './DateDialog'
import { NotesDialog } from './NotesDialog'
import { TypeDialog } from './TypeDialog'
import { LocationDialog } from './LocationDialog'
import { List } from './List'
import { OrderDetails } from './OrderDetails'
import { Success } from './Success'
import { Actions } from './Actions'
import { shiftDefault } from './lib/shiftFunctions'
import { RecurringOptions } from './RecurringOptions'
import { RecurringSelection } from './RecurringSelection'
import { weekdayFor } from './lib/timeFunctions'

const StyledBackdrop = styled(Backdrop)`
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
`

const ContainerManager = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: 100px;
  padding-bottom: 100px;

  .shake {
    animation: shake 0.52s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

const ShiftInfoCover = styled(Paper)(
  ({ theme }) => `
  height: 100%;
  width: 450px;
  margin-right: 8px;
  ${theme.breakpoints.down('md')} {
    margin-bottom: 8px;
  }
  ${theme.breakpoints.down('sm')} {
    margin-right: 0px;
    width: 95vw;
    margin-bottom: 8px;
  }
`,
)

const ShiftInfo = styled(Grid)`
  padding: 20px;
`

const ShiftDetail = styled.div(
  ({ theme }) => `
  width: 630px;
  margin-bottom: 8px;
  ${theme.breakpoints.between('md', 'lg')} {
    width: 590px;
  }
  ${theme.breakpoints.down('sm')} {
    width: 95vw;
  }
`,
)

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`

export const ShiftDialog = ({
  open,
  handleClose,
  handleCreatePosition,
  positions,
  partnerships,
  lastLocation,
  addressLocation,
  isAgency,
  quickData,
  staffDepartments,
  isFacility,
  latestAgencyData,
  isAgencyShiftEnabled,
  inHouseStaffEnabled,
  showTagManagement,
  dynamicStaffWage,
}) => {
  const [currentPosition, positionHandler] = useState(shiftDefault.position)
  const [currentQty, qtyHandler] = useState(shiftDefault.qty)
  const [currentStartAt, startAtHandler] = useState(shiftDefault.startAt)
  const [currentEndAt, endAtHandler] = useState(shiftDefault.endAt)
  const [currentDuration, durationHandler] = useState(shiftDefault.duration)
  const [currentBreakDuration, breakDurationHandler] = useState(shiftDefault.breakDuration)
  const [currentBreakQty, breakQtyHandler] = useState(shiftDefault.breakQty)
  const [currentUnpaid, unpaidHandler] = useState(shiftDefault.unpaid)
  const [currentDates, datesHandler] = useState(shiftDefault.dates)
  const [currentRecurring, recurringHandler] = useState(shiftDefault.recurring)
  const [currentLocation, locationHandler] = useState(shiftDefault.location)
  const [errorLocation, setErrorLocation] = useState(null)
  const [currentCity, cityHandler] = useState(shiftDefault.city)
  const [currentRegion, regionHandler] = useState(shiftDefault.region)
  const [currentCountry, countryHandler] = useState(shiftDefault.country)
  const [currentNotes, notesHandler] = useState(shiftDefault.notes)
  const [shifts, shiftsHandler] = useState([])
  const [contactName, contactNameHandler] = useState(null)
  const [contactPhone, contactPhoneHandler] = useState(null)
  const [poNumber, poNumberHandler] = useState(null)
  const [signupLocation, signupLocationHandler] = useState(null)
  const [orderNotes, orderNotesHandler] = useState(null)
  const [shake, setShake] = useState(false)
  const [days, daysHandler] = useState(shiftDefault.days)
  const [endDate, endDateHandler] = useState(shiftDefault.endDate)
  const [shiftPosition, setShiftPosition] = useState(0)
  const [orderForAll, setOrderForAll] = useState(true)

  const setDefaultShift = useCallback((submit) => {
    positionHandler(positions[0])
    qtyHandler(shiftDefault.qty)
    startAtHandler(shiftDefault.startAt)
    endAtHandler(shiftDefault.endAt)
    durationHandler(shiftDefault.duration)
    breakDurationHandler(shiftDefault.breakDuration)
    breakQtyHandler(shiftDefault.breakQty)
    unpaidHandler(shiftDefault.unpaid)
    datesHandler(quickData ? [quickData.date] : shiftDefault.dates)
    recurringHandler(shiftDefault.recurring)
    notesHandler(shiftDefault.notes)
    daysHandler(shiftDefault.days)
    endDateHandler(shiftDefault.endDate)
    if (submit) {
      signupLocationHandler(null)
      contactNameHandler(null)
      contactPhoneHandler(null)
      poNumberHandler(null)
      orderNotesHandler(null)
      setOrderForAll(true)
    }
  }, [positions, quickData])

  const actionHandler = (actionType) => () => {
    handleCreatePosition(
      {
        shifts,
        contactName,
        contactPhone,
        poNumber,
        signupLocation,
        orderNotes,
        actionType,
      },
      orderForAll,
    )

    setDefaultShift(true)
    shiftsHandler([])
  }

  const closeHandler = () => {
    handleClose()
    setDefaultShift(true)
    shiftsHandler([])
  }

  const shakeShift = () => {
    setShake(true)
    setTimeout(() => setShake(false), 1000)
  }

  const canSubmit = useMemo(() => {
    const stringValidate = (key) => key == null || key === ''
    let validate = false
    if (shifts.filter((shift) => shift.type === 'agency').length > 0) {
      if (orderForAll) {
        validate = stringValidate(signupLocation) || stringValidate(contactName) || stringValidate(contactPhone)
      } else {
        const validList = []
        shifts.map((shift) => {
          if (shift.type === 'agency') {
            const validSignupLocation = stringValidate(shift.signupLocation)
            const validContactName = stringValidate(shift.contactName)
            const validContactPhone = stringValidate(shift.contactPhone)
            validList.push(validSignupLocation || validContactName || validContactPhone)
          }
          return validList
        })
        validate = validList.some((invalid) => invalid)
      }
    }

    const shiftsErrors = shifts.filter((shift) => {
      if (shift.type === 'agency') {
        if (isFacility) {
          return shift.department == null || shift.agency == null || validate
        }
        return shift.agency == null || validate
      }
      if (shift.type === 'inHouse') {
        if (isFacility) {
          return shift.shiftType == null || shift.department == null
        }
        return shift.shiftType == null
      }
      return false
    })
    if (quickData && quickData.user) {
      return true
    }
    return !(shiftsErrors.length > 0)
  }, [shifts, quickData, orderForAll, signupLocation, contactName, contactPhone, isFacility])

  const canHyre = useMemo(() => {
    if (dayjs().startOf('d')
      .diff(dayjs(currentDates).startOf('d')
        .set('m', currentStartAt), 'd', true) > 0) {
      return false
    }
    return false
  }, [currentStartAt, currentDates])

  const canInHouse = useMemo(
    () => {
      if (!inHouseStaffEnabled) {
        return false
      }
      if (shifts.length > 0 && quickData && quickData.user) {
        return false
      }
      return currentPosition && currentPosition.inhouse
    },
    [currentPosition, shifts, quickData, inHouseStaffEnabled],
  )

  const canAgency = useMemo(
    () => {
      if (isAgencyShiftEnabled && currentPosition) {
        const { id } = currentPosition

        const possible = partnerships.filter(
          (partnership) => {
            const matched = partnership.departmentPositions.filter(
              (departmentPosition) => {
                const apl = departmentPosition.adminPositionList
                return parseInt(apl.id, 10) === parseInt(id, 10)
              },
            )

            return matched.length > 0
          },
        )

        return possible.length > 0
      }

      return false
    },
    [currentPosition, partnerships, isAgencyShiftEnabled],
  )

  const shiftHandler = useCallback(
    (type) => {
      if (!errorLocation) {
        shiftsHandler([
          ...shifts,
          {
            index: shiftPosition,
            position: currentPosition,
            qty: currentQty,
            startAt: currentStartAt,
            endAt: currentEndAt,
            duration: currentDuration,
            breakDuration: currentBreakDuration,
            breakQty: currentBreakQty,
            unpaid: currentUnpaid,
            dates: currentDates,
            recurring: currentRecurring,
            notes: currentNotes,
            location: currentLocation,
            city: currentCity,
            region: currentRegion,
            country: currentCountry,
            agency: null,
            department: null,
            shiftType: quickData && quickData.assignOnly ? quickData.assignOnly : false,
            signupLocation: null,
            contactName: null,
            contactPhone: null,
            poNumber: null,
            orderNotes: null,
            type,
            days,
            endDate,
          },
        ])
        setDefaultShift(false)
        setShiftPosition(shiftPosition + 1)
      }
    },
    [
      errorLocation,
      shifts,
      shiftPosition,
      currentPosition,
      currentQty,
      currentStartAt,
      currentEndAt,
      currentDuration,
      currentBreakDuration,
      currentBreakQty,
      currentUnpaid,
      currentDates,
      currentRecurring,
      currentNotes,
      currentLocation,
      currentCity,
      currentRegion,
      currentCountry,
      quickData,
      days,
      endDate,
      setDefaultShift,
    ],
  )

  useEffect(() => {
    if (currentLocation) {
      setErrorLocation(null)
    } else {
      setErrorLocation('This is required!')
    }
  }, [currentLocation])

  useEffect(() => {
    if (lastLocation) {
      locationHandler(lastLocation)
    }
  }, [lastLocation])

  const switchOrderHandle = useCallback(
    (checked, shift) => {
      setOrderForAll(checked)
      if (!checked) {
        const shiftList = [...shifts]
        shiftList.forEach((item) => {
          const data = item
          if (
            data.type === 'agency'
            && !data.signupLocation
            && !data.contactName
            && !data.contactPhone
            && !data.poNumber
            && !data.orderNotes
          ) {
            data.signupLocation = signupLocation
            data.contactName = contactName
            data.contactPhone = contactPhone
            data.poNumber = poNumber
            data.orderNotes = orderNotes
          }
          return data
        })
        shiftsHandler(shiftList)
      }
      if (shift && checked) {
        contactNameHandler(shift.contactName)
        contactPhoneHandler(shift.contactPhone)
        orderNotesHandler(shift.orderNotes)
        poNumberHandler(shift.poNumber)
        signupLocationHandler(shift.signupLocation)
      }
    },
    [contactName, contactPhone, orderNotes, poNumber, shifts, signupLocation],
  )

  const shiftRemover = (targeted) => {
    if (shifts.length === 1) {
      setOrderForAll(true)
    }
    shiftsHandler(shifts.filter((shift) => shift !== targeted))
  }

  const shiftUpdater = useCallback(
    (updatedShift, toUpdateIndex) => shiftsHandler(
      shifts.map(
        (shift, index) => (index === toUpdateIndex ? updatedShift : shift),
      ),
    ),
    [shifts],
  )

  const hasShifts = useMemo(() => shifts.length > 0, [shifts])

  useEffect(() => {
    if (quickData) {
      const { date } = quickData
      datesHandler([date])
      endDateHandler(dayjs(date).add(1, 'week')
        .add(1, 'day'))
    } else {
      datesHandler(shiftDefault.dates)
    }
  }, [quickData])

  useEffect(() => {
    if (positions.length > 0) {
      if (quickData) {
        if (quickData.type === 'in-house') {
          positionHandler(positions.filter((position) => position.inhouse)[0])
        } else {
          positionHandler(positions.filter((position) => position.agency)[0])
        }
      } else {
        positionHandler(positions[0])
      }
    }
  }, [positions, quickData])

  useEffect(() => {
    if (latestAgencyData) {
      const { orderDetail } = latestAgencyData
      contactNameHandler(orderDetail.contactName)
      contactPhoneHandler(orderDetail.contactNumber)
      poNumberHandler(orderDetail.poNumber)
      signupLocationHandler(orderDetail.signUpLocation)
      orderNotesHandler(orderDetail.orderNotes)
    }
  }, [latestAgencyData])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={HyreTheme}>
        <StyledBackdrop open={open}>
          <ContainerManager>
            <ShiftInfoCover className={shake ? 'shake' : ''}>
              <Title closeHandler={closeHandler} quickData={quickData} />
              <ShiftInfo container spacing={3}>
                <Grid item xs={12}>
                  <PositionDialog
                    position={currentPosition}
                    positions={
                      quickData
                        ? positions
                          .filter((position) => (quickData.type === 'in-house' ? position.inhouse : position.agency))
                        : positions
                    }
                    positionHandler={positionHandler}
                    qty={currentQty}
                    qtyHandler={qtyHandler}
                    hasStaff={!!quickData && !!quickData.user}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimeDialog
                    startAt={currentStartAt}
                    startAtHandler={startAtHandler}
                    endAt={currentEndAt}
                    endAtHandler={endAtHandler}
                    durationHandler={durationHandler}
                    breakDuration={currentBreakDuration}
                    breakDurationHandler={breakDurationHandler}
                    breakQty={currentBreakQty}
                    breakQtyHandler={breakQtyHandler}
                    unpaid={currentUnpaid}
                    unpaidHandler={unpaidHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateWrapper>
                    <DateDialog
                      disabled={!!quickData}
                      dates={currentDates}
                      datesHandler={(date) => {
                        datesHandler(date)
                        daysHandler([weekdayFor(dayjs(date))])
                        endDateHandler(dayjs(date).add(1, 'week')
                          .add(1, 'day'))
                      }}
                    />
                    <RecurringOptions recurring={currentRecurring} recurringHandler={recurringHandler} />
                  </DateWrapper>
                  <RecurringSelection
                    recurring={currentRecurring}
                    days={days}
                    daysHandler={daysHandler}
                    endDate={endDate}
                    endDateHandler={endDateHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationDialog
                    location={currentLocation}
                    locationHandler={(value) => locationHandler(value ? value.description : null)}
                    errorLocation={errorLocation}
                    setErrorLocation={setErrorLocation}
                    addressLocation={addressLocation}
                    cityHandler={cityHandler}
                    regionHandler={regionHandler}
                    countryHandler={countryHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NotesDialog notes={currentNotes} notesHandler={notesHandler} />
                </Grid>
                <Grid item xs={12}>
                  <TypeDialog
                    canAgency={canAgency}
                    canHyre={canHyre}
                    canInHouse={canInHouse}
                    shiftHandler={shiftHandler}
                    isAgency={isAgency}
                    type={quickData && quickData.type}
                  />
                </Grid>
              </ShiftInfo>
            </ShiftInfoCover>
            {hasShifts && (
              <ShiftDetail>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Success />
                  </Grid>
                  <Grid item xs={12}>
                    <List
                      shifts={shifts}
                      isFacility={isFacility}
                      hasStaff={!!quickData && !!quickData.user}
                      removeHandler={shiftRemover}
                      updateHandler={shiftUpdater}
                      partnerships={partnerships}
                      quickData={quickData}
                      staffDepartments={staffDepartments}
                      orderForAll={orderForAll}
                      setOrderForAll={switchOrderHandle}
                      showTagManagement={showTagManagement}
                      dynamicStaffWage={dynamicStaffWage}
                    />
                  </Grid>
                  {shifts.some((item) => item.type === 'agency') && orderForAll && (
                    <Grid item xs={12}>
                      <OrderDetails
                        contactName={contactName}
                        contactNameHandler={contactNameHandler}
                        contactPhone={contactPhone}
                        contactPhoneHandler={contactPhoneHandler}
                        poNumber={poNumber}
                        poNumberHandler={poNumberHandler}
                        signupLocation={signupLocation}
                        signupLocationHandler={signupLocationHandler}
                        orderNotes={orderNotes}
                        orderNotesHandler={orderNotesHandler}
                        orderForAll={orderForAll}
                        setOrderForAll={switchOrderHandle}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Actions
                      shakeShift={shakeShift}
                      draftHandler={actionHandler('draft')}
                      postHandler={actionHandler('post')}
                      enabled={canSubmit}
                    />
                  </Grid>
                </Grid>
              </ShiftDetail>
            )}
          </ContainerManager>
        </StyledBackdrop>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
